import React, { createContext } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

export const AppContext = createContext({
  imageBaseUrl: '',
  asPath: '',
});

const AppProvider = ({ children }) => {
  const {
    publicRuntimeConfig: { baseUrl: imageBaseUrl = '' },
  } = getConfig();
  const { asPath = '' } = useRouter();

  return (
    <AppContext.Provider value={{ imageBaseUrl, currentRoute: asPath }}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
