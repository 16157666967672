export const actions = {
  FETCH_FAVOURITES: 'FETCH_FAVOURITES',
  FETCH_FAVOURITES_SUCCESS: 'FETCH_FAVOURITES_SUCCESS',
  ADD_FAVOURITE: 'ADD_FAVOURITE',
  REMOVE_FAVOURITE: 'REMOVE_FAVOURITE',
};

export const initialState = {
  venues: [],
  spaces: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.FETCH_FAVOURITES: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.FETCH_FAVOURITES_SUCCESS: {
      const { type } = action.payload;
      const { favourites } = action.payload;

      return {
        ...state,
        loading: false,
        [type]: favourites,
      };
    }

    case actions.ADD_FAVOURITE: {
      const { type } = action.payload;
      const { favourite } = action.payload;

      return {
        ...state,
        [type]: [...state[type], favourite].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      };
    }

    case actions.REMOVE_FAVOURITE: {
      const { type } = action.payload;
      const favourites = state[type];
      const { favourite } = action.payload;

      return {
        ...state,
        [type]: favourites.filter(item => item.id !== favourite.id),
      };
    }

    default:
      return state;
  }
};

export default reducer;
