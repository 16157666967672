import { createContext, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState, actions } from './reducer';

// hooks
import { useApiRequest } from '../../hooks/useApiRequest';

export const FavouritesContext = createContext({
  ...initialState,
  actions: {
    fetchFavourites: async () => {},
    addFavourite: async () => {},
    removeFavourite: async () => {},
  },
});

const FavouritesProvider = ({ children }) => {
  const [{ venues, spaces }, dispatch] = useReducer(reducer, initialState);

  const apiRequest = useApiRequest();

  const fetchFavourites = useCallback(
    async type => {
      dispatch({ type: actions.FETCH_FAVOURITES });
      const { data } = await apiRequest.get(`/v1/users/favourites/${type}`);

      dispatch({
        type: actions.FETCH_FAVOURITES_SUCCESS,
        payload: { favourites: data, type },
      });
    },
    [apiRequest]
  );

  const addFavourite = useCallback(
    async (id, type) => {
      const { data } = await apiRequest.post(`/v1/users/favourites/${type}`, {
        id,
      });

      dispatch({
        type: actions.ADD_FAVOURITE,
        payload: { favourite: data, type },
      });
    },
    [apiRequest]
  );

  const removeFavourite = useCallback(
    async (id, type) => {
      const { data } = await apiRequest.delete(
        `/v1/users/favourites/${type}/${id}`
      );

      dispatch({
        type: actions.REMOVE_FAVOURITE,
        payload: { favourite: data, type },
      });
    },
    [apiRequest]
  );

  const actionMethods = {
    fetchFavourites,
    addFavourite,
    removeFavourite,
  };

  return (
    <FavouritesContext.Provider
      value={{
        venues,
        spaces,
        actions: actionMethods,
      }}
    >
      {children}
    </FavouritesContext.Provider>
  );
};

FavouritesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FavouritesProvider;
