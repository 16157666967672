import { useEffect, useState } from 'react';

import App from 'next/app';
import getConfig from 'next/config';
import TagManager from 'react-gtm-module';
import Axios from 'axios';
import Router from 'next/router';

// context providers
import { Cookies, CookiesProvider } from 'react-cookie';
import AppProvider from '../state/app';
import AuthProvider from '../state/auth';
import SearchProvider from '../state/search';
import FavouritesProvider from '../state/favourites';
import BookingProvider from '../state/booking';
import BookingsProvider from '../state/bookings';
import BasketProvider from '../state/basket';
import MobileHeaderProvider from '../state/mobileHeader';

import MaintenancePage from '../components/maintenancePage';

// utils
import { appWithTranslation } from '../utils/i18n';

// stylings
import '../styles/application.scss';

const { publicRuntimeConfig } = getConfig();

const MyApp = ({ Component, pageProps, cookies }) => {
  const [token, setToken] = useState(undefined);

  useEffect(() => {
    if (publicRuntimeConfig.gtmId) {
      TagManager.initialize({ gtmId: publicRuntimeConfig.gtmId });
    }
  }, []);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        if (cookies.refresh_token) {
          const client = Axios.create({
            baseURL: `${publicRuntimeConfig.baseUrl}/api`,
            withCredentials: true,
            headers: {
              Accept: `application/json`,
            },
          });

          const { data } = await client.post('/v1/users/auth/refresh_token');

          setToken(data.token);
        }
      } catch (e) {
        Router.replace('/sign-in');
      }
    };

    refreshToken();
  }, [Component, cookies.refresh_token]);

  const [cookieStore] = useState(() => new Cookies(cookies));

  if (publicRuntimeConfig.maintenance === 'true') {
    return <MaintenancePage />;
  }

  return (
    <CookiesProvider cookies={cookieStore}>
      <AppProvider>
        <AuthProvider token={token}>
          <MobileHeaderProvider>
            <SearchProvider>
              <FavouritesProvider>
                <BookingProvider>
                  <BasketProvider>
                    <BookingsProvider>
                      <Component {...pageProps} />
                    </BookingsProvider>
                  </BasketProvider>
                </BookingProvider>
              </FavouritesProvider>
            </SearchProvider>
          </MobileHeaderProvider>
        </AuthProvider>
      </AppProvider>
    </CookiesProvider>
  );
};

MyApp.getInitialProps = async appContext => {
  const { pageProps = {} } = await App.getInitialProps(appContext);
  const { ctx: { req: { cookies = {} } = {} } = {} } = appContext;

  return { pageProps, cookies };
};

export default appWithTranslation(MyApp);
