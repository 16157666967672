import Head from 'next/head';
import Link from 'next/link';

const MaintenancePage = () => {
  return (
    <>
      <Head>
        <title>Maintenance Page</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=yes"
        />
      </Head>
      <div id="home">
        <div className="maintenance-header">
          <div className="header__nav">
            <Link href="/">
              <a>
                <div className="header__logo header__logo--absolute">
                  <img src="/images/openspace-logo.svg" alt="NHS Open Space" />
                </div>
                <div className="header__logo header__logo--sticky">
                  <img src="/images/openspace-logo.svg" alt="NHS Open Space" />
                </div>
              </a>
            </Link>
          </div>
        </div>
        <div className="maintenance-banner">
          <div className="inner">
            <div className="banner__content">
              <h1 className="banner__heading">
                Open Space is not available due to scheduled maintenance.
                <br />
                Please check back soon!
              </h1>

              <p className="banner__intro" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaintenancePage;
