import { useContext, useEffect } from 'react';

import { AuthContext } from '../auth';
import useStoredBooking from '../../hooks/useStoredBooking';

const BookingProvider = ({ children }) => {
  const { loggedIn } = useContext(AuthContext);
  const [, , removeBooking] = useStoredBooking();

  useEffect(() => {
    if (!loggedIn) removeBooking();
  }, [loggedIn, removeBooking]);

  return children;
};

export default BookingProvider;
